var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map" },
    [
      _c("GoogleMapsLoader", {
        ref: "loader",
        attrs: {
          context: _vm.context,
          config: _vm.mapConfig,
          apiKey: _vm.apiKey,
        },
        on: { map__initialized: _vm.onMapLoaded },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var google = ref.google
              var map = ref.map
              return _vm._l(_vm.markers, function (marker) {
                return _c("GoogleMapMarker", {
                  key: marker.title,
                  attrs: {
                    position: marker.position,
                    icon: _vm.iconUrl,
                    google: google,
                    map: map,
                    "info-content": marker.text,
                  },
                })
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }