








































import {forEach, isEmpty, map, PlainObject} from '@labor-digital/helferlein';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import GoogleMapsLoader from '../../../Component/Branch/BranchMap/GoogleMapLoader.vue';
import GoogleMapMarker from '../../../Component/Branch/BranchMap/GoogleMapMarker.vue';

export default {
    name: 'Map',
    components: {GoogleMapMarker, GoogleMapsLoader},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        mapConfig()
        {
            return {
                center: {lat: 51, lng: 10},
                zoom: 6,
                fullscreenControl: false,
                mapTypeControl: false,
                panControl: false,
                zoomControl: false,
                scaleControl: false
            };
        },
        apiKey(): String
        {
            return this.context.pageContext.data.get('googleMapsApiKey');
        },
        iconUrl(): string
        {
            return this.context.pageContext.baseUrl + '/assets/icons/map-pin-green.svg';
        },
        markers(): Array<{ text: string, position: { lat: number, lng: number } }>
        {
            return map(this.context.data.get('markers', []), (v: PlainObject) => {
                return {
                    text: '<h4>' + (v.title ?? '') + '</h4>' + (v.text ?? ''),
                    position: {
                        lat: parseFloat(v.lat ?? '0'),
                        lng: parseFloat(v.lng ?? '0')
                    }
                };
            });
        }
    },
    methods: {
        onMapLoaded()
        {
            // Zoom to points
            if (!isEmpty(this.markers)) {
                const mapsApi = this.$refs.loader.google.maps;
                const map = this.$refs.loader.map;
                const bounds = new mapsApi.LatLngBounds();
                forEach(this.markers, marker => {
                    const pos = marker.position;
                    bounds.extend(new mapsApi.LatLng(pos.lat, pos.lng));
                });
                map.setOptions({maxZoom: 12});
                map.fitBounds(bounds);
                setTimeout(() => map.setOptions({maxZoom: undefined}), 400);
            }
        }
    }
};
